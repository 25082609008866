import Carousel from 'react-grid-carousel'
import { MenuPreview } from '@components/product/Menu'
import { Typography } from '@mui/material'
import { Button, Link } from '@components/ui'
import { FC } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config'
import colors from '@const/colors.json'
import mClassique from '@assets/images/img-m-classique.png'
import mClassiqueWebP from '@assets/images/img-m-classique.webp'
import mCold from '@assets/images/img-m-burof.png'
import mColdWebP from '@assets/images/img-m-burof.webp'
import mDiet from '@assets/images/img-m-diete.png'
import mDietWebP from '@assets/images/img-m-diete.webp'
import mVegan from '@assets/images/img-m-vege.png'
import mVeganWebP from '@assets/images/img-m-vege.webp'
import mGroumet from '@assets/images/img-m-gourmand.png'
import mGroumetWebP from '@assets/images/img-m-gourmand.webp'
import mBowl from '@assets/images/img-m-yabowl.png'
import mBowlWebP from '@assets/images/img-m-yabowl.webp'
import mHot from '@assets/images/img-m-buroc.png'
import mHotWebP from '@assets/images/img-m-buroc.webp'
import mChildren from '@assets/images/img-m-enfant.png'
import mChildrenWebP from '@assets/images/img-m-enfant.webp'

interface ComponentProps {}

const Menu: FC<ComponentProps> = () => {
  // @ts-ignore
  const fullConfig = resolveConfig(tailwindConfig)

  const menus = [
    {
      image: mClassique,
      imageWebP: mClassiqueWebP,
      title: 'Menu Classique',
      bgColor: colors.red,
      imgPosition: 'top-[-10px]',
      w: 374,
      h: 427
    },
    {
      image: mDiet,
      imageWebP: mDietWebP,
      title: 'Menu Diététique',
      subtitle: 'Élaboré en collaboration avec une diététicienne nutritionniste',
      bgColor: colors.lightgreen,
      imgPosition: 'top-[20px]',
      w: 367,
      h: 385
    },
    {
      image: mVegan,
      imageWebP: mVeganWebP,
      title: 'Menu Végétarien',
      bgColor: colors.blue4,
      imgPosition: 'top-[30px] left-[40px]',
      w: 324,
      h: 337
    },
    {
      image: mGroumet,
      imageWebP: mGroumetWebP,
      title: 'Menu Gourmand',
      bgColor: colors.yellow1,
      imgPosition: 'top-[40px] left-[20px] -rotate-45',
      w: 360,
      h: 354
    },
    {
      image: mBowl,
      imageWebP: mBowlWebP,
      title: 'Menu Ya Bowl',
      bgColor: colors.blue6,
      imgPosition: 'top-[30px] left-[40px]',
      w: 341,
      h: 337
    },
    {
      image: mHot,
      imageWebP: mHotWebP,
      title: 'Menu Buro Chaud',
      bgColor: colors.green,
      imgPosition: 'top-[30px] left-[40px]',
      w: 345,
      h: 341
    },
    {
      image: mCold,
      imageWebP: mColdWebP,
      title: 'Menu Buro Froid',
      bgColor: colors.orange,
      imgPosition: 'top-[30px] left-[40px]',
      w: 343,
      h: 343
    },
    {
      image: mChildren,
      imageWebP: mChildrenWebP,
      title: 'Gastro´môme',
      bgColor: colors.pink,
      imgPosition: 'top-[30px] left-[40px]',
      w: 345,
      h: 339
    }
  ]

  const castBreakPoint = (breakpoint: string | undefined): number | void => {
    if (breakpoint) return parseInt(breakpoint.replace('px', ''))
  }

  return (
    <div className="container mx-auto my-12.5">
      <div className="xl:grid xl:grid-cols-4">
        <div className="xl:col-span-2 xl:col-start-2">
          <div className="mb-5">
            <Typography component="h2" variant="h2" className="text-center text-2xl sm:text-[2.1875rem]">
              Chaque semaines, des nouveaux plats
            </Typography>
          </div>
          <Typography component="p" variant="body1" className="px-3 text-center">
            Nous privilégions les produits de saison.
            <br /> Super Ya, c’est une alimentation variée et adaptée à tous les régimes alimentaires.
          </Typography>
        </div>
      </div>
      <div className="mt-7.5">
        <Carousel
          cols={4}
          rows={1}
          responsiveLayout={[
            {
              // @ts-ignore
              breakpoint: castBreakPoint(fullConfig?.theme?.screens?.lg),
              cols: 3
            }
          ]}
        >
          {menus.map((menu, index) => (
            <Carousel.Item key={index}>
              <MenuPreview
                image={menu.image}
                imageWebP={menu.imageWebP}
                imgPosition={menu.imgPosition}
                title={menu.title}
                subtitle={menu.subtitle}
                bgColor={menu.bgColor}
                imageSize="h-71 lg:h-[380px] mx-[15px]"
                w={menu.w}
                h={menu.h}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="mt-11 text-center">
        <Link href="/menus">
          <Button variant="contained" color="primary" disableElevation>
            <span className="py-2 font-bold normal-case">Voir tous nos menus</span>
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default Menu
