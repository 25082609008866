import React, { FC, ReactNode } from 'react'
import { Typography } from '@mui/material'

interface ComponentProps {
  icon: ReactNode
  title: string
}

const TextBottom: FC<ComponentProps> = ({ icon, title }) => {
  return (
    <div className="mt-7.5 flex flex-col items-center space-y-[10px]">
      {icon}
      <Typography>{title}</Typography>
    </div>
  )
}

export default TextBottom
