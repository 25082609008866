import Detail from '@components/practical_information'
import { Typography } from '@mui/material'
import { FC } from 'react'
import data from '@const/super-ya.json'
import TimeIcon from '@assets/images/svg/ico-horaire.svg'
import LocationIcon from '@assets/images/svg/ico-location.svg'
import HouseIcon from '@assets/images/svg/ico-shop.svg'

interface ComponentProps {}

const PracticalInformation: FC<ComponentProps> = () => {
  const { opening, address, contact } = data

  return (
    <div className="container mx-auto px-4">
      <div className="sm:grid sm:grid-cols-12">
        <div className="sm:col-span-5 sm:col-start-1 lg:col-span-7 lg:col-start-1 xl:col-span-6 xl:col-start-1 2xl:col-span-6 2xl:col-start-1">
          <Typography variant="h2" component="h2">
            <span className="mobile:text-2xl">Les infos pratiques</span>
          </Typography>
          <Typography className="mb-7.5 mt-5">
            Tout sur votre service de gamelles,
            <br /> où et quand.
          </Typography>
          <div className="lg:grid lg:grid-cols-2">
            <Detail title={`Ouverture : ${opening.label}`} icon={<TimeIcon />}>
              {opening.day} <br />
              de {opening.hour}
            </Detail>
            <Detail title="Contacts" icon={<HouseIcon />}>
              Tél : {contact.phone}
              <br />
              Email : {contact.email}
            </Detail>
            <Detail title="Adresse" icon={<LocationIcon />}>
              {address.street} - {address.district}
              <br />
              {address.postcode} - {address.zipcode} {address.city}
            </Detail>
          </div>
        </div>
        <div className="mt-7.5 sm:col-span-7 sm:mt-0 lg:col-span-5 lg:col-start-8 xl:col-span-6 xl:col-start-7 2xl:col-span-6 2xl:col-start-7">
          <div
            className="googlemapsembed h-[180px] w-full sm:h-full"
            data-url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.420155064336!2d166.46079151549054!3d-22.262068785343352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6c28081937e52fb9%3A0x709c51bad7105879!2sSUPER%20YA%20Gamelle!5e0!3m2!1sfr!2sfr!4v1634098300325!5m2!1sfr!2sfr"
          />
        </div>
      </div>
    </div>
  )
}

export default PracticalInformation
