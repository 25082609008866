import { Typography } from '@mui/material'
import { FC, PropsWithChildren, ReactNode } from 'react'

interface ComponentProps {
  icon?: ReactNode
  title: string
}

const Detail: FC<PropsWithChildren<ComponentProps>> = ({ icon, title, children }) => {
  const rootClasses = icon ? 'grid grid-cols-12' : ''
  const gridClasses = icon ? 'col-span-10 pl-2' : ''

  return (
    <div className={`mt-7.5 ${rootClasses}`}>
      {icon ? <div className="col-span-1 justify-self-start">{icon}</div> : ''}
      <div className={`${gridClasses}`}>
        <Typography className="font-extrabold">{title}</Typography>
        <Typography>{children}</Typography>
      </div>
    </div>
  )
}

export default Detail
