const colors = require('./const/colors.json')
const plugin = require('tailwindcss/plugin')

const {
  black1,
  black2,
  black3,
  blue,
  blue1,
  blue3,
  blue4,
  blue5,
  blue7,
  blue9,
  blue10,
  blue11,
  blueclub,
  blueopacity,
  yellow,
  yellow1,
  yellow2,
  grey,
  grey1,
  grey2,
  greengrey,
  lightgreen,
  lightgrey,
  whiteGrey,
  white,
  orange,
  red
} = colors

module.exports = {
  purge: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    '../../shared/common/components/**/*.{js,ts,jsx,tsx}',
    '../../shared/common/models/**/*.{js,ts,jsx,tsx}',
    '../../shared/core/components/**/*.{js,ts,jsx,tsx}'
  ],
  darkMode: false, // or 'media' or 'class'
  mode: 'jit',
  important: true,
  theme: {
    extend: {
      spacing: {
        '7.5': '1.875rem', // 30px
        '12.5': '3.125rem', // 50px
        '24.5': '6.25rem', // 100px
        '50': '12.5rem', // 200px
        '71': '17.5rem', // 280px
        '75': '18.75rem', // 300px
        '104': '26rem' // 416px
      },
      colors: {
        primary: '#3a9ee0',
        dark: {
          '200': 'rgb(129, 174, 193)',
          '400': 'rgb(0, 163, 218)',
          '600': 'rgb(0, 158, 224)'
        },
        blue2: {
          '100': 'rgba(0, 158, 224, .1)',
          '200': 'rgba(0, 158, 224, .2)',
          '300': 'rgba(0, 158, 224, .4)',
          '400': 'rgba(0, 158, 224 , .6)',
          '500': 'rgba(0, 158, 224 , .8)',
          '600': 'rgb(0, 158, 224)',
          '700': 'rgb(0, 158, 224)',
          '800': 'rgb(0, 158, 224)',
          '900': 'rgb(0, 158, 224)'
        },
        'super-ya': {
          black1,
          black2,
          black3,
          blue,
          blue1,
          blue3,
          blue4,
          blue5,
          blue7,
          blue9,
          blue10,
          blue11,
          blueclub,
          blueopacity,
          yellow,
          yellow1,
          yellow2,
          grey,
          grey1,
          grey2,
          greengrey,
          lightgreen,
          lightgrey,
          whiteGrey,
          white,
          orange,
          red
        }
      },
      opacity: {
        '15': '0.15'
      }
    },
    fontFamily: {
      nunito: ['Nunito'],
      newsreader: ['Newsreader']
    },
    screens: {
      '2xs': '320px',
      xs: '372px',
      sm: '640px',
      // => @media (min-width: 640px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1024px',
      // => @media (min-width: 1024px) { ... }

      xl: '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }
      full: { min: '1280px' },
      responsive: { max: '1280px' },
      'xl-responsive': { max: '1024px' },
      'responsive-lg': { min: '768px', max: '1024px' },
      small: { min: '540px', max: '768px' },
      tablet: { max: '768px' },
      mobile: { max: '540px' },
      'extra-small': { max: '372px' }
    }
  },
  plugins: [
    plugin(function ({ addUtilities }) {
      addUtilities(
        {
          '.scrollbar-w-0': {
            scrollbarWidth: 'none'
          }
        },
        {
          variants: ['responsive']
        }
      )
    })
  ]
}
