import { Typography } from '@mui/material'
import { FC } from 'react'

interface ComponentProps {
  image: any
  imageWebP: any
  title: string
  subtitle?: string
  bgColor: string
  imageSize?: string
  imgPosition?: string
  w: number
  h: number
}

const Preview: FC<ComponentProps> = ({ image, imageWebP, title, subtitle, bgColor, imageSize, imgPosition, w, h }) => {
  return (
    <>
      <div className={`relative mb-5 overflow-hidden rounded ${imageSize}`} style={{ backgroundColor: bgColor }}>
        <picture>
          <source srcSet={imageWebP.src} type="image/webp" />
          <img className={`absolute max-w-none ${imgPosition}`} src={image.src} alt={title} width={w} height={h} />
        </picture>
      </div>
      <Typography component="p" variant="h3" className="pb-2 text-center">
        {title}
      </Typography>
      {subtitle ? (
        <Typography className="px-6 text-center" variant="body2">
          {subtitle}
        </Typography>
      ) : null}
    </>
  )
}

export default Preview
