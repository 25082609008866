import { Typography } from '@mui/material'
import { FC } from 'react'
import { TextBottom } from '@components/common/Icon/index'
import { Button, CTA } from '@components/ui'
import CbIcon from '@assets/images/svg/ico-cb.svg'
import CdIcon from '@assets/images/svg/ico-carte-dejeuner.svg'
import CheckIcon from '@assets/images/svg/ico-cheque.svg'
import CashIcon from '@assets/images/svg/ico-especes.svg'
import TransferIcon from '@assets/images/svg/ico-virement.svg'
import CartIcon from '@assets/images/svg/ico-remplir-panier.svg'
import bg from '@assets/images/img-poele.png'
import bgWebP from '@assets/images/img-poele.webp'
import routes from '@const/routes'

interface ComponentProps {}

const Paiement: FC<ComponentProps> = () => {
  const availPaiements = [
    {
      icon: <CheckIcon />,
      text: 'Chèque',
      className: 'col-span-2 sm:col-span-1'
    },
    {
      icon: <CdIcon />,
      text: 'Carte déjeuner',
      className: 'col-span-2 sm:col-span-1'
    },
    {
      icon: <CbIcon />,
      text: 'Carte Bleue',
      className: 'col-span-2 sm:col-span-1'
    },
    {
      icon: <CashIcon />,
      text: 'Espèces',
      className: 'col-span-3 sm:col-span-1'
    },
    {
      icon: <TransferIcon />,
      text: 'Virement bancaire',
      className: 'col-span-3 sm:col-span-1'
    }
  ]

  return (
    <div className="container relative mx-auto mb-24.5 mt-20 bg-super-ya-blue3 bg-opacity-10 px-4 pb-10 pt-8 xl:mb-44 xl:pb-12.5">
      <div className="absolute bottom-0 left-0 -mb-16 -ml-16 hidden xl:block">
        <picture>
          <source srcSet={bgWebP.src} type="image/webp" />
          <img src={bg.src} height={bg.height} width={bg.width} alt="" />
        </picture>
      </div>
      <div className="mb-5">
        <Typography variant="h2" component="h2" className="text-center">
          <span className="text-2xl md:text-4xl">Commander & payer, rien de plus facile</span>
        </Typography>
        <div className="mt-5 hidden sm:block">
          <Typography variant="body1" component="p" className="text-center">
            Les modes de réglement acceptés.
          </Typography>
        </div>
      </div>
      <div className="xl:grid xl:grid-cols-5">
        <div className="xl:col-span-3 xl:col-start-2">
          <div className="place-items-top mb-10 grid grid-cols-6 sm:grid-cols-5">
            {availPaiements.map((paiement, index) => (
              <div className={paiement.className} key={index}>
                <TextBottom icon={paiement.icon} title={paiement.text} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 -mb-7 flex w-full justify-center xl:w-full">
        <CTA
          label="Je remplis mon panier"
          url={routes.menus.uri}
          icon={<CartIcon />}
          reverse
          wrapper={({ children }) => (
            <Button variant="contained" color="primary" className="w-[90%] sm:w-80" disableElevation>
              {children}
            </Button>
          )}
          hover="white"
        />
      </div>
    </div>
  )
}

export default Paiement
